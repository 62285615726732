.why-choose-us {
    position: relative;
    z-index: 1;
  }
  .why-choose-us::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #0d1028;
    opacity: 0.7;
  }
  .why-choose-us .slideshow {
    z-index: -1;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .why-choose-us .slideshow li span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    backface-visibility: hidden;
    animation: imageAnimation 24s linear infinite 0s;
  }
  .why-choose-us .slideshow li:nth-child(2) span {
    animation-delay: 6s;
  }
  .why-choose-us .slideshow li:nth-child(3) span {
    animation-delay: 12s;
  }
  .why-choose-us .slideshow li:nth-child(4) span {
    animation-delay: 18s;
  }
  
  .col-lg-3:first-child .single-box {
    border-left: none;
  }
  
  .single-box {
    position: relative;
    z-index: 1;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    height: 600px;
    vertical-align: top;
  }
  .single-box .d-table-cell {
    vertical-align: top;
  }
  .single-box .content {
    position: absolute;
    padding: 100px 25px;
    left: 0;
    transition: 0.5s;
  }
  .single-box .content::before {
    content: "";
    position: absolute;
    bottom: 5px;
    width: 100%;
    max-width: 280px;
    text-align: justify;
    height: 260px;
    left: 50%;
    margin-left: -155px;
    z-index: -1;
    border: 10px solid rgba(104, 89, 222, 0.03);
  }
  .single-box .content .icon {
    font-size: 45px;
    color: #FF2D55;
  }
  .single-box .content h3 {
    font-size: 26px;
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .single-box .content p {
    color: #ffffff;
    margin-bottom: 0;
    transition: 0.5s;
  }
  .single-box .content .btn {
    opacity: 0;
    visibility: hidden;
  }
  .single-box:hover .content, .single-box:focus .content {
    bottom: 35px;
  }
  .single-box:hover .content p, .single-box:focus .content p {
    margin-bottom: 25px;
  }
  .single-box:hover .content .btn, .single-box:focus .content .btn {
    opacity: 1;
    visibility: visible;
  }
  
  @keyframes imageAnimation {
    0% {
      opacity: 0;
      animation-timing-function: ease-in;
    }
    12.5% {
      opacity: 1;
      animation-timing-function: ease-out;
    }
    25% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .no-cssanimations .slideshow li span {
    opacity: 1;
  }
  
  /*why-choose-style-two*/
  .why-choose-us-two {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .why-choose-content {
    text-align: center;
    padding: 20px 25px;
    position: relative;
    z-index: 1;
  }
  .why-choose-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .why-choose-content p {
    margin-bottom: 0;
  }
  .why-choose-content a {
    color: #FF2D55;
    margin-top: 15px;
  }
  .why-choose-content a:hover, .why-choose-content a:focus {
    letter-spacing: 1px;
  }
  .why-choose-content span {
    font-size: 100px;
    font-weight: 700;
    color: #f2f2f2;
    line-height: 90px;
    position: absolute;
    left: 0;
    right: 0;
    margin: -10px auto 0;
    z-index: -1;
    top: 0;
  }