  /* Untuk desktop */
@media (width> 1024px) {
  .funfacts-area {
    position: relative;
    z-index: 1;
    background-image: url("../Assets/funfacts-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 90px 0;
  }
  .funfacts-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #0d1028;
    opacity: 0.88;
  }
  
  .single-funfact {
    text-align: center;
  }
  .single-funfact .icon {
    color: #ffffff;
    font-size: 40px;
  }
  .single-funfact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .single-funfact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  /*funfact-style-two*/
  .funFact {
    position: relative;
    padding-left: 60px;
  }
  .funFact .icon {
    color: #ffffff;
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .funFact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .funFact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  .col-lg-3:nth-child(1) .single-funfact h3, .col-lg-3:nth-child(1) .funFact h3 {
    color: #FF2D55;
  }
  .col-lg-3:nth-child(2) .single-funfact h3, .col-lg-3:nth-child(2) .funFact h3 {
    color: #00acee;
  }
  .col-lg-3:nth-child(3) .single-funfact h3, .col-lg-3:nth-child(3) .funFact h3 {
    color: #efa506;
  }
  .col-lg-3:nth-child(4) .single-funfact h3, .col-lg-3:nth-child(4) .funFact h3 {
    color: #8dc63f;
  }
  
/*section-title*/
.section-title {
  position: relative;
  margin-bottom: 60px;
  z-index: 1;
}
.section-title span {
  color: #FF2D55;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}
.section-title h2 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-title h2 b {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  font-weight: unset;
  background-size: 100px 1.07em !important;
}
.section-title .btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -18px;
}
.section-title .bg-title {
  color: rgba(73, 57, 231, 0.05);
  font-weight: 700;
  font-size: 140px;
  line-height: 120px;
  position: absolute;
  top: 0;
  right: 35%;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: -1;
  margin-top: -25px;
}
.section-title .bar {
  position: relative;
  width: 20px;
  height: 4px;
  background: #FF2D55;
  display: block;
}
.section-title .bar::after, .section-title .bar::before {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
}
.section-title .bar::after {
  width: 25px;
  background: #00acee;
  right: -29px;
}
.section-title .bar::before {
  background: #efa506;
  width: 30px;
  right: -63px;
}
}
  
/* Untuk tablet */
@media (width> 768px){
  .funfacts-area {
    position: relative;
    z-index: 1;
    background-image: url("../Assets/funfacts-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 90px 0;
  }
  .funfacts-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #0d1028;
    opacity: 0.88;
  }
  
  .single-funfact {
    text-align: center;
  }
  .single-funfact .icon {
    color: #ffffff;
    font-size: 40px;
  }
  .single-funfact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .single-funfact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  /*funfact-style-two*/
  .funFact {
    position: relative;
    padding-left: 60px;
  }
  .funFact .icon {
    color: #ffffff;
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .funFact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .funFact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  .col-lg-3:nth-child(1) .single-funfact h3, .col-lg-3:nth-child(1) .funFact h3 {
    color: #FF2D55;
  }
  .col-lg-3:nth-child(2) .single-funfact h3, .col-lg-3:nth-child(2) .funFact h3 {
    color: #00acee;
  }
  .col-lg-3:nth-child(3) .single-funfact h3, .col-lg-3:nth-child(3) .funFact h3 {
    color: #efa506;
  }
  .col-lg-3:nth-child(4) .single-funfact h3, .col-lg-3:nth-child(4) .funFact h3 {
    color: #8dc63f;
  }
  
/*section-title*/
.section-title {
  position: relative;
  margin-bottom: 60px;
  z-index: 1;
}
.section-title span {
  color: #FF2D55;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}
.section-title h2 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-title h2 b {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  font-weight: unset;
  background-size: 100px 1.07em !important;
}
.section-title .btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -18px;
}
.section-title .bg-title {
  color: rgba(73, 57, 231, 0.05);
  font-weight: 700;
  font-size: 140px;
  line-height: 120px;
  position: absolute;
  top: 0;
  right: 35%;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: -1;
  margin-top: -25px;
}
.section-title .bar {
  position: relative;
  width: 20px;
  height: 4px;
  background: #FF2D55;
  display: block;
}
.section-title .bar::after, .section-title .bar::before {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
}
.section-title .bar::after {
  width: 25px;
  background: #00acee;
  right: -29px;
}
.section-title .bar::before {
  background: #efa506;
  width: 30px;
  right: -63px;
}
}

/* Untuk ponsel kecil */
@media (width< 768px) {
  .funfacts-area {
    position: relative;
    z-index: 1;
    background-image: url("../Assets/funfacts-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 90px 0;
  }
  .funfacts-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #0d1028;
    opacity: 0.88;
  }
  
  .single-funfact {
    text-align: center;
  }
  .single-funfact .icon {
    color: #ffffff;
    font-size: 40px;
  }
  .single-funfact h3 {
    color: #ffffff;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .single-funfact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 10px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  /*funfact-style-two*/
  .funFact {
    position: relative;
    padding-left: 60px;
  }
  .funFact .icon {
    color: #ffffff;
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .funFact h3 {
    color: #ffffff;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .funFact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 15px;
    margin-bottom: 0;
    line-height: 1;
  }
  
  .col-lg-3:nth-child(1) .single-funfact h3, .col-lg-3:nth-child(1) .funFact h3 {
    color: #FF2D55;
  }
  .col-lg-3:nth-child(2) .single-funfact h3, .col-lg-3:nth-child(2) .funFact h3 {
    color: #00acee;
  }
  .col-lg-3:nth-child(3) .single-funfact h3, .col-lg-3:nth-child(3) .funFact h3 {
    color: #efa506;
  }
  .col-lg-3:nth-child(4) .single-funfact h3, .col-lg-3:nth-child(4) .funFact h3 {
    color: #8dc63f;
  }
  
/*section-title*/
.section-title {
  position: relative;
  margin-bottom: 60px;
  z-index: 1;
}
.section-title span {
  color: #FF2D55;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}
.section-title h2 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-title h2 b {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  font-weight: unset;
  background-size: 100px 1.07em !important;
}
.section-title .btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -18px;
}
.section-title .bg-title {
  color: rgba(73, 57, 231, 0.05);
  font-weight: 700;
  font-size: 140px;
  line-height: 120px;
  position: absolute;
  top: 0;
  right: 35%;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: -1;
  margin-top: -25px;
}
.section-title .bar {
  position: relative;
  width: 20px;
  height: 4px;
  background: #FF2D55;
  display: block;
}
.section-title .bar::after, .section-title .bar::before {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
}
.section-title .bar::after {
  width: 25px;
  background: #00acee;
  right: -29px;
}
.section-title .bar::before {
  background: #efa506;
  width: 30px;
  right: -63px;
}
}


