

  /* Untuk desktop */
@media (width> 1024px) {
  .elkevent-single-speakers {
    overflow: hidden;
    position: relative;
  }
  .elkevent-single-speakers .speakers-content {
    position: absolute;
    bottom: -25px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 25px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .elkevent-single-speakers .speakers-content h3 a {
    color: #0d1028;
  }
  .elkevent-single-speakers .speakers-content h3 a:hover, .elkevent-single-speakers .speakers-content h3 a:focus {
    color: #FF2D55;
  }
  .elkevent-single-speakers .speakers-content span {
    display: block;
    margin-top: 8px;
    color: #FF2D55;
    transition: 0.5s;
  }
  .elkevent-single-speakers ul {
    padding: 15px 20px 10px;
    margin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    list-style-type: none;
    transition: 0.5s;
    position: absolute;
    right: -15px;
    top: 0;
    background-color: #ffffff;
  }
  .elkevent-single-speakers ul li {
    display: block;
    margin-bottom: 5px;
  }
  .elkevent-single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #FF2D55;
    color: #ffffff;
    border-radius: 50%;
    font-size: 14px;
  }
  
  .elkevent-single-speakers img {
    transition: 0.5s;
  }
  .elkevent-single-speakers:hover .speakers-content, .elkevent-single-speakers:focus .speakers-content {
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover ul, .elkevent-single-speakers:focus ul {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover img, .elkevent-single-speakers:focus img {
    transform: scale(1.2);
    filter: grayscale(100%);
  }
  
  /*Speakers-style-three*/
  .speakers-area {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
/*section-title*/
.section-title {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
  }
  .section-title span {
    color: #FF2D55;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  .section-title h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .section-title h2 b {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
    font-weight: unset;
    background-size: 100px 1.07em !important;
  }
  .section-title .btn {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -18px;
  }
  .section-title .bg-title {
    color: rgba(73, 57, 231, 0.05);
    font-weight: 700;
    font-size: 140px;
    line-height: 120px;
    position: absolute;
    top: 0;
    right: 35%;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: -1;
    margin-top: -25px;
  }
  .section-title .bar {
    position: relative;
    width: 20px;
    height: 4px;
    background: #FF2D55;
    display: block;
  }
  .section-title .bar::after, .section-title .bar::before {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
  }
  .section-title .bar::after {
    width: 25px;
    background: #00acee;
    right: -29px;
  }
  .section-title .bar::before {
    background: #efa506;
    width: 30px;
    right: -63px;
  }
}
/* ===================================== */
/* ===================================== */
/* ===================================== */
/* ===================================== */
/* ===================================== */
/* ===================================== */

/* Untuk tablet */
@media (width> 768px){
  .elkevent-single-speakers {
    overflow: hidden;
    position: relative;
  }
  .elkevent-single-speakers .speakers-content {
    position: absolute;
    bottom: -25px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 25px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .elkevent-single-speakers .speakers-content h3 a {
    color: #0d1028;
  }
  .elkevent-single-speakers .speakers-content h3 a:hover, .elkevent-single-speakers .speakers-content h3 a:focus {
    color: #FF2D55;
  }
  .elkevent-single-speakers .speakers-content span {
    display: block;
    margin-top: 8px;
    color: #FF2D55;
    transition: 0.5s;
  }
  .elkevent-single-speakers ul {
    padding: 15px 20px 10px;
    margin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    list-style-type: none;
    transition: 0.5s;
    position: absolute;
    right: -15px;
    top: 0;
    background-color: #ffffff;
  }
  .elkevent-single-speakers ul li {
    display: block;
    margin-bottom: 5px;
  }
  .elkevent-single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #FF2D55;
    color: #ffffff;
    border-radius: 50%;
    font-size: 14px;
  }
  
  .elkevent-single-speakers img {
    transition: 0.5s;
    width: 100%;
  }
  .elkevent-single-speakers:hover .speakers-content, .elkevent-single-speakers:focus .speakers-content {
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover ul, .elkevent-single-speakers:focus ul {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover img, .elkevent-single-speakers:focus img {
    transform: scale(1.2);
    filter: grayscale(100%);
  }
  
  /*Speakers-style-three*/
  .speakers-area {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
/*section-title*/
.section-title {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
  }
  .section-title span {
    color: #FF2D55;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  .section-title h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .section-title h2 b {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
    font-weight: unset;
    background-size: 100px 1.07em !important;
  }
  .section-title .btn {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -18px;
  }
  .section-title .bg-title {
    color: rgba(73, 57, 231, 0.05);
    font-weight: 700;
    font-size: 140px;
    line-height: 120px;
    position: absolute;
    top: 0;
    right: 35%;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: -1;
    margin-top: -25px;
  }
  .section-title .bar {
    position: relative;
    width: 20px;
    height: 4px;
    background: #FF2D55;
    display: block;
  }
  .section-title .bar::after, .section-title .bar::before {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
  }
  .section-title .bar::after {
    width: 25px;
    background: #00acee;
    right: -29px;
  }
  .section-title .bar::before {
    background: #efa506;
    width: 30px;
    right: -63px;
  }
}
/* ======================================================== */
/* ======================================================== */
/* ======================================================== */
/* ======================================================== */
/* ======================================================== */
/* ======================================================== */

/* Untuk ponsel kecil */
@media (width< 768px) {
  .elkevent-single-speakers {
    overflow: hidden;
    position: relative;
  }
  .elkevent-single-speakers .speakers-content {
    position: absolute;
    bottom: 25px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 25px;
    opacity: 0;
    /* visibility: hidden; */
    transition: 0.5s;
  }
  .elkevent-single-speakers .speakers-content h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .elkevent-single-speakers .speakers-content h3 a {
    color: #ffffff;
  }
  .elkevent-single-speakers .speakers-content h3 a:hover, .elkevent-single-speakers .speakers-content h3 a:focus {
    color: #FF2D55;
  }
  .elkevent-single-speakers .speakers-content span {
    display: block;
    margin-top: 8px;
    color: #FF2D55;
    transition: 0.5s;
  }
  .elkevent-single-speakers ul {
    padding: 15px 20px 10px;
    margin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    list-style-type: none;
    transition: 0.5s;
    position: absolute;
    right: -15px;
    top: 0;
    background-color: #ffffff;
  }
  .elkevent-single-speakers ul li {
    display: block;
    margin-bottom: 5px;
  }
  .elkevent-single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #FF2D55;
    color: #ffffff;
    border-radius: 50%;
    font-size: 14px;
  }
  
  .elkevent-single-speakers img {
    transition: 0.5s;
    width: 100%;
  }
  .elkevent-single-speakers:hover .speakers-content, .elkevent-single-speakers:focus .speakers-content {
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover ul, .elkevent-single-speakers:focus ul {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .elkevent-single-speakers:hover img, .elkevent-single-speakers:focus img {
    transform: scale(1.2);
    filter: grayscale(100%);
  }
  
  /*Speakers-style-three*/
  .speakers-area {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
/*section-title*/
.section-title {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
  }
  .section-title span {
    color: #FF2D55;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  .section-title h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .section-title h2 b {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
    font-weight: unset;
    background-size: 100px 1.07em !important;
  }
  .section-title .btn {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -18px;
  }
  .section-title .bg-title {
    color: rgba(73, 57, 231, 0.05);
    font-weight: 700;
    font-size: 140px;
    line-height: 120px;
    position: absolute;
    top: 0;
    right: 35%;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: -1;
    margin-top: -25px;
  }
  .section-title .bar {
    position: relative;
    width: 20px;
    height: 4px;
    background: #FF2D55;
    display: block;
  }
  .section-title .bar::after, .section-title .bar::before {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
  }
  .section-title .bar::after {
    width: 25px;
    background: #00acee;
    right: -29px;
  }
  .section-title .bar::before {
    background: #efa506;
    width: 30px;
    right: -63px;
  }
}
  

 
  