/* Untuk desktop */
@media (width> 1024px) {
  .location {
    padding-top:5px;
    font-weight:600;
    color:black;
  }
  
  .schedule-area {
    position: relative;
    z-index: 1;
  }
  .schedule-area.bg-image {
    background-image: url("../Assets/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  /*schedule-style-two*/
  .schedule-slides-item .single-schedule {
    position: relative;
    background: #ffffff;
    text-align: center;
    padding: 0 0 0 200px;
  }
  .schedule-slides-item .single-schedule .schedule-date {
    font-size: 24px;
    color: #ffffff;
    background: #ff2c54;
    text-transform: uppercase;
    padding: 25px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px;
  }
  .schedule-slides-item .single-schedule .schedule-date span {
    display: block;
    font-size: 15px;
    text-transform: capitalize;
    margin-top: 5px;
  }
  .schedule-slides-item .single-schedule .schedule-content {
    display: flex;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    /* untuk padding sebelah gambarnya */
    padding: 20px; 
    text-align: left;
  }
  .schedule-slides-item .single-schedule .schedule-content .author {
    max-width: 200px;
    margin-right: 25px;
  }

  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a {
    color: #0d1028;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    font-size: 14px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li i {
    margin-right: 6px;
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a {
    display: inline-block;
    color: #00acee;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule:nth-child(2) .schedule-date {
    background: #00aced;
  }
  .schedule-slides-item .single-schedule:nth-child(3) .schedule-date {
    background: #eea406;
  }
  .schedule-slides-item .single-schedule:nth-child(4) .schedule-date {
    background: #8dc53e;
  }
  .schedule-slides-item .single-schedule:nth-child(5) .schedule-date {
    background: #942cff;
  }
  .schedule-slides-item .single-schedule:nth-child(6) .schedule-date {
    background: #ed00df;
  }
  .schedule-slides-item .single-schedule:nth-child(7) .schedule-date {
    background: #ff2c54;
  }

  /*================================================
  Default CSS
  =================================================*/
  body {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }

  a {
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: none;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }
  .d-table-cell {
    vertical-align: middle;
  }

  .ptb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .img2 {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  p {
    line-height: 1.9;
    color: #707070;
    margin-bottom: 15px;
    font-size: 15px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #0d1028;
  }

  .text-right {
    text-align: end;
  }

  .text-left {
    text-align: start;
  }

  .form-group {
    margin-bottom: 30px;
  }

  textarea.form-control {
    min-height: auto !important;
    height: auto;
  }

  label {
    margin-bottom: 10px;
  }

  /*btn btn-primary*/
  /* bukan ini */
  .btn {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
  }

  .btn2 {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
    background-color: #FF2D55;
    width: 50%; 
    margin: 20px auto 70px auto;
    display: block;
  }
}


/* =========================================================== */
/* =========================================================== */
/* =========================================================== */
/* =========================================================== */

/* Untuk tablet */
@media (width> 768px){
  .location {
    padding-top:5px;
    font-weight:600;
    color:black;
  }
  .schedule-area {
    position: relative;
    z-index: 1;
  }
  .schedule-area.bg-image {
    background-image: url("../Assets/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  /*schedule-style-two*/
  .schedule-slides-item .single-schedule {
    position: relative;
    background: #ffffff;
    text-align: center;
    padding: 0 0 0 200px;
  }
  .schedule-slides-item .single-schedule .schedule-date {
    font-size: 24px;
    color: #ffffff;
    background: #ff2c54;
    text-transform: uppercase;
    padding: 25px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px;
  }
  .schedule-slides-item .single-schedule .schedule-date span {
    display: block;
    font-size: 15px;
    text-transform: capitalize;
    margin-top: 5px;
  }
  .schedule-slides-item .single-schedule .schedule-content {
    display: flex;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    /* untuk padding sebelah gambarnya */
    padding: 20px; 
    text-align: left;
  }
  .schedule-slides-item .single-schedule .schedule-content .author {
    max-width: 200px;
    margin-right: 25px;
  }

  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a {
    color: #0d1028;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    font-size: 14px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li i {
    margin-right: 6px;
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a {
    display: inline-block;
    color: #00acee;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule:nth-child(2) .schedule-date {
    background: #00aced;
  }
  .schedule-slides-item .single-schedule:nth-child(3) .schedule-date {
    background: #eea406;
  }
  .schedule-slides-item .single-schedule:nth-child(4) .schedule-date {
    background: #8dc53e;
  }
  .schedule-slides-item .single-schedule:nth-child(5) .schedule-date {
    background: #942cff;
  }
  .schedule-slides-item .single-schedule:nth-child(6) .schedule-date {
    background: #ed00df;
  }
  .schedule-slides-item .single-schedule:nth-child(7) .schedule-date {
    background: #ff2c54;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }

  a {
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: none;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }
  .d-table-cell {
    vertical-align: middle;
  }

  .ptb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .img2 {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  p {
    line-height: 1.9;
    color: #707070;
    margin-bottom: 15px;
    font-size: 15px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #0d1028;
  }

  .text-right {
    text-align: end;
  }

  .text-left {
    text-align: start;
  }

  .form-group {
    margin-bottom: 30px;
  }

  textarea.form-control {
    min-height: auto !important;
    height: auto;
  }

  label {
    margin-bottom: 10px;
  }

  /*btn btn-primary*/
  /* bukan ini */
  .btn {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
  }

  .btn2 {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
    background-color: #FF2D55;
    width: 50%; 
    margin: 20px auto 70px auto;
    display: block;
  }
}
/* ================================================================ */
/* ================================================================ */
/* ================================================================ */
/* ================================================================ */
/* ================================================================ */
/* Untuk ponsel kecil */
@media (width< 768px) {
  .location {
    padding-top:5px;
    font-weight:600;
    color:black;
    font-size: 10px;
    display: inline;
  }
  .schedule-area {
    position: relative;
    z-index: 1;
  }
  .schedule-area.bg-image {
    background-image: url("../Assets/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  /*schedule-style-two*/
  /* .schedule-slides-item .single-schedule {
    position: relative;
    background: #ffffff;
    text-align: center;
    padding: 0 0 0 200px;
  } */
  .schedule-slides-item .single-schedule .schedule-date {
    font-size: 15px;
    color: #ffffff;
    background: #ff2c54;
    text-transform: uppercase;
    padding: 10px;
    position: relative;
    width: 100%;
    text-align: center;
  }
  .schedule-slides-item .single-schedule .schedule-date span {
    display: block;
    font-size: 12px;
    text-transform: capitalize;
    margin-top: 5px;
    text-align: center;
  }
  .schedule-slides-item .single-schedule .schedule-content {
    display: flex;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    /* untuk padding sebelah gambarnya */
    padding: 20px; 
    text-align: left;
  }
  .schedule-slides-item .single-schedule .schedule-content .author {
    max-width: 200px;
    margin-right: 25px;
  }

  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a {
    color: #0d1028;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    font-size: 10px;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li i {
    margin-right: 6px;
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a {
    display: inline-block;
    color: #00acee;
  }
  .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:hover, .schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:focus {
    color: #FF2D55;
  }
  .schedule-slides-item .single-schedule:nth-child(2) .schedule-date {
    background: #00aced;
  }
  .schedule-slides-item .single-schedule:nth-child(3) .schedule-date {
    background: #eea406;
  }
  .schedule-slides-item .single-schedule:nth-child(4) .schedule-date {
    background: #8dc53e;
  }
  .schedule-slides-item .single-schedule:nth-child(5) .schedule-date {
    background: #942cff;
  }
  .schedule-slides-item .single-schedule:nth-child(6) .schedule-date {
    background: #ed00df;
  }
  .schedule-slides-item .single-schedule:nth-child(7) .schedule-date {
    background: #ff2c54;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }

  a {
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: none;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }
  .d-table-cell {
    vertical-align: middle;
  }

  /* .ptb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  } */

  img {
    max-width: 100%;
    height: auto;
  }

  .img2 {
    max-width: 100%;
    height: auto;
  }

  p {
    line-height: 1.9;
    color: #707070;
    margin-bottom: 15px;
    font-size: 15px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #0d1028;
  }

  .text-right {
    text-align: end;
  }

  .text-left {
    text-align: start;
  }

  .form-group {
    margin-bottom: 30px;
  }

  textarea.form-control {
    min-height: auto !important;
    height: auto;
  }

  label {
    margin-bottom: 10px;
  }

  /*btn btn-primary*/
  /* bukan ini */
  .btn {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
    /* display: none; */
  }

  .btn2 {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: 0.5s;
    background-color: #FF2D55;
    width: 50%; 
    margin: 20px auto 70px auto;
    display: block;
  }
}