/* Untuk desktop */
@media (width> 1024px) {
  
.countdown-area {
  padding: 40px 0;
  background: #ffc341;
}
.countdown-area .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: center;
}
.countdown-area .event-countdown #timer div {
  margin: 0 55px;
}
.countdown-area .event-countdown #timer div#seconds {
  color: #FF2D55;
}

.event-countdown {
  position: absolute;
  right: 10%;
  /* bottom: 20px; */
  text-align: right;
}
.event-countdown #timer div {
  display: inline-block;
  font-size: 70px;
  font-weight: 700;
  color: #ffffff;
  /* margin: 0 35px; */
  position: relative;
}
.event-countdown #timer div#days {
  color: #FF2D55;
}
.event-countdown #timer div#hours {
  color: #00acee;
}
.event-countdown #timer div#hours span {
  right: -50px;
  margin-top: -12px;
}
.event-countdown #timer div#minutes {
  color: #ff7b00;
}
.event-countdown #timer div#minutes span {
  right: -60px;
  margin-top: -12px;
}
.event-countdown #timer div#seconds {
  color: #efa506;
}
.event-countdown #timer div#seconds span {
  right: -60px;
  margin-top: -12px;
}
.event-countdown #timer span {
  display: block;
  color: #ffffff;
  margin-top: 0;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  right: -40px;
  top: 50%;
  text-transform: uppercase;
  margin-top: -10px;
  letter-spacing: 2px;
}

}

/* Untuk tablet */
@media (width> 768px) {
  .countdown-area {
    padding: 40px 0;
    background: #ffc341;
  }
  .countdown-area .event-countdown {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: center;
  }
  .countdown-area .event-countdown #timer div {
    margin: 0 55px;
  }
  .countdown-area .event-countdown #timer div#seconds {
    color: #FF2D55;
  }
  
  .event-countdown {
    position: absolute;
    right: 10%;
    /* bottom: 20px; */
    text-align: right;
  }
  .event-countdown #timer div {
    display: inline-block;
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    /* margin: 0 35px; */
    position: relative;
  }
  .event-countdown #timer div#days {
    color: #FF2D55;
  }
  .event-countdown #timer div#hours {
    color: #00acee;
  }
  .event-countdown #timer div#hours span {
    right: -50px;
    margin-top: -12px;
  }
  .event-countdown #timer div#minutes {
    color: #ff7b00;
  }
  .event-countdown #timer div#minutes span {
    right: -60px;
    margin-top: -12px;
  }
  .event-countdown #timer div#seconds {
    color: #efa506;
  }
  .event-countdown #timer div#seconds span {
    right: -60px;
    margin-top: -12px;
  }
  .event-countdown #timer span {
    display: block;
    color: #ffffff;
    margin-top: 0;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    right: -40px;
    top: 50%;
    text-transform: uppercase;
    margin-top: -10px;
    letter-spacing: 2px;
  }
}

/* Untuk ponsel kecil */
@media (width< 768px) {
  .countdown-area {
    padding: 20px 0;
    background: #ffc341;
  }
  .countdown-area .event-countdown {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: center;
  }
  .countdown-area .event-countdown #timer div {
    margin: 0 55px;
  }
  .countdown-area .event-countdown #timer div#seconds {
    color: #FF2D55;
  }
  
  .event-countdown {
    position: absolute;
    right: 10%;
    /* bottom: 20px; */
    text-align: right;
  }
  .event-countdown #timer div {
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    /* margin: 0 35px; */
    position: relative;
  }
  .event-countdown #timer div#days {
    color: #FF2D55;
  }
  .event-countdown #timer div#hours {
    color: #00acee;
  }
  .event-countdown #timer div#hours span {
    right: -50px;
    margin-top: -12px;
  }
  .event-countdown #timer div#minutes {
    color: #ff7b00;
  }
  .event-countdown #timer div#minutes span {
    right: -60px;
    margin-top: -12px;
  }
  .event-countdown #timer div#seconds {
    color: #efa506;
  }
  .event-countdown #timer div#seconds span {
    right: -60px;
    margin-top: -12px;
  }
  .event-countdown #timer span {
    display: block;
    color: #ffffff;
    margin-top: 0;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    right: -40px;
    top: 50%;
    text-transform: uppercase;
    margin-top: -10px;
    letter-spacing: 2px;
  }
}



